
import { CollectionInvoiceType } from '.';
import { apiFetch, HTTPMethod, getFileName } from '../fetch';
import { CollectionInvoice, InitiatePaymentResult, AccountingInvoiceCreationInfo, ExternalPaymentTransaction, UnproccessedTransaction, UpdateStatementTransactions, CollectionInvoiceStatus, PaymentTransactionListItem } from './interface';
import download from 'downloadjs';
import { IossImportDataEntryModel } from '../iossOrderInformation';
import { CompanyEmail } from '../companies';

export const getStatements = (fromDate: string, toDate: string) =>
  apiFetch<CollectionInvoice[]>(`/collectionInvoice?invoiceType=${CollectionInvoiceType.Statement}&fromDate=${fromDate}&toDate=${toDate}`);

export const getCollectionInvoices = (invoiceType:CollectionInvoiceType, fromDate: string, toDate: string) =>
  apiFetch<CollectionInvoice[]>(`/collectionInvoice?invoiceType=${invoiceType}&fromDate=${fromDate}&toDate=${toDate}`);

export const getCollectionInvoicesForCompanyId = (companyId: string, invoiceType: CollectionInvoiceType) =>
  apiFetch<CollectionInvoice[]>(`/collectionInvoice/${companyId}?invoiceType=${invoiceType}`);

export const getStatementReminderEmails = () =>
  apiFetch<CompanyEmail[]>('/collectionInvoice/statementReminderEmails');

export const getCollectionInvoice = (companyId: string, collectionInvoiceId: string, includeRows: boolean = false) =>
  apiFetch<CollectionInvoice>(`/collectionInvoice/${companyId}/${collectionInvoiceId}?includeRows=${includeRows}`);

export const getCompanyFirstInitialSubscriptionCollectionInvoice = (companyId: string) =>
  apiFetch<CollectionInvoice>(`/collectionInvoice/${companyId}/firstInitialSubscriptionInvoice`);

export const createInitialSubscriptionInvoice = (companyId: string) =>
  apiFetch<CollectionInvoice>(`/collectionInvoice/${companyId}/createInitialSubscriptionInvoice`, undefined, HTTPMethod.POST);

export const getInitialSubscriptionInvoice = (companyId: string, subscriptionIds: string[]) =>
  apiFetch<CollectionInvoice>(`/collectionInvoice/${companyId}/getInitialSubscriptionInvoice`, subscriptionIds, HTTPMethod.POST);

export const getOrCreateInitialSubscriptionInvoice = (companyId: string, subscriptionIds: string[]) =>
  apiFetch<CollectionInvoice>(`/collectionInvoice/${companyId}/getOrCreateInitialSubscriptionInvoice`, subscriptionIds, HTTPMethod.POST);

export const createStatements = (jobIds: string[], periodEnd: string) =>
  apiFetch<number>('/collectionInvoice/statements', { jobIds, periodEnd }, HTTPMethod.POST);

export const createFeeInvoices = (jobIds: string[], periodEnd: string, companiesExcludedFromDiscount: string[]) =>
  apiFetch<number>('/collectionInvoice/feeinvoices', { jobIds, periodEnd, companiesExcludedFromDiscount }, HTTPMethod.POST);

function pdfDownloadCatch (e: { status?: string; message?: string; title?: string; }) {
  if (e.status) {
    if (e.message) throw new Error(`${e.status}: ${e.message}`);
    else if (e.title) throw new Error(`${e.status}: ${e.title}`);
  }
  throw e;
}

async function processPdfResponse (result: Response) {
  const fileName = getFileName(result);
  const blob = await result.blob();
  return { blob, fileName };
}

export const downloadCollectionInvoicePdf = (companyId: string, collectionInvoice: CollectionInvoice) =>
  apiFetch<Response>(`/collectionInvoice/${companyId}/${collectionInvoice.id}/pdfFile`)
    .then(processPdfResponse)
    .then(data => {
      let fileName = data.fileName;
      if (!fileName) {
        if (collectionInvoice.invoiceType === CollectionInvoiceType.Statement) {
          fileName = `FileName-${collectionInvoice.id}.pdf`;
        } else {
          fileName = `SalesInvoice_${collectionInvoice.invoiceNumber}.pdf`;
        }
      }
      return download(data.blob, fileName );
    })
    .catch(pdfDownloadCatch);

export const downloadCollectionInvoicePdfPreview = (companyId: string, collectionInvoice: CollectionInvoice) =>
  apiFetch<Response>(`/collectionInvoice/${companyId}/${collectionInvoice.id}/pdfFilePreview`)
    .then(processPdfResponse)
    .then(data => {
      let fileName = data.fileName;
      if (!fileName) {
        fileName = `Preview-FileName-${collectionInvoice.id}.pdf`;
      }
      return download(data.blob, fileName);
    })
    .catch(pdfDownloadCatch);
export const clearBankWireInfo = (companyId: string, collectionInvoiceId: string) =>
  apiFetch(`/collectionInvoice/${companyId}/${collectionInvoiceId}/clearbankwire`, undefined, HTTPMethod.PUT);

export const clearInitiatedCardPayment = (companyId: string, collectionInvoiceId: string) =>
  apiFetch(`/collectionInvoice/${companyId}/${collectionInvoiceId}/clearInitiatedCardPayment`, undefined, HTTPMethod.PUT);

export const updatePDF = (collectionInvoiceId: string) =>
  apiFetch(`/collectionInvoice/${collectionInvoiceId}/updatePDF`, undefined, HTTPMethod.PUT);

export const sendStatements = (collectionInvoiceIds: string[]) =>
  apiFetch('/collectionInvoice/sendstatements', collectionInvoiceIds, HTTPMethod.POST);

export const sendStatementReminderEmails = (collectionInvoiceIds: string[]) =>
  apiFetch('/collectionInvoice/sendStatementReminderEmails', collectionInvoiceIds, HTTPMethod.PUT);

export const getCollectionInvoiceDetails = (companyId: string, collectionInvoiceId: string) =>
  apiFetch<IossImportDataEntryModel[]>(`/collectionInvoice/${companyId}/${collectionInvoiceId}/orders`);

export const deleteCollectionInvoices = (collectionInvoiceIds: string[]) =>
  apiFetch<string[]>('/collectionInvoice', collectionInvoiceIds, HTTPMethod.DELETE);

export const getStatementPdfGeneratorStatus = () =>
  apiFetch<boolean>('/collectionInvoice/StatementPdfGeneratorStatus');

export const migrateStatements = (companyIds?: string[]) =>
  apiFetch('/migrateStatements', companyIds ?? [], HTTPMethod.POST);

export const getExternalPayments = (companyId: string) =>
  apiFetch<ExternalPaymentTransaction[]>(`/migrateStatements/${companyId}/payments`);

export const getUnprocessedTransactions = (companyId?: string) =>
  apiFetch<UnproccessedTransaction[]>(`/collectionInvoice/unprocessedTransactions${companyId ? `?companyId=${companyId}` : ''}`);

export const initiateZuluCardPayment = (companyId: string, collectionInvoiceId: string, isMerchantInitiated: boolean) =>
  apiFetch<InitiatePaymentResult>(`/collectionInvoice/${companyId}/${collectionInvoiceId}/paywithzulucard?isMerchantInitiated=${isMerchantInitiated}`, undefined, HTTPMethod.PUT);

export const initiateBankwirePayment = (companyId: string, collectionInvoiceId: string) =>
  apiFetch<InitiatePaymentResult>(`/collectionInvoice/${companyId}/${collectionInvoiceId}/bankwire`, undefined, HTTPMethod.PUT);

export const getAccountingInvoiceCreationInfo = () =>
  apiFetch<AccountingInvoiceCreationInfo>('/accounting/invoices/creationQueueInfo');

export const queueInvoicesForAccountingCreation = (collectionInvoiceIds: string[]) =>
  apiFetch('/accounting/invoices/queueInvoiceCreation', collectionInvoiceIds, HTTPMethod.POST);

export const createInvoiceInERP = (collectionInvoiceId: string) =>
  apiFetch<CollectionInvoice>(`/accounting/invoices/${collectionInvoiceId}`, null, HTTPMethod.POST);

export const changeCollectionInvoiceStatus = (collectionInvoiceId: string, status: CollectionInvoiceStatus) =>
  apiFetch<CollectionInvoice>(`/collectionInvoice/${collectionInvoiceId}/changeStatus`, status, HTTPMethod.PUT);

export const clearCollectionInvoiceErrors = (collectionInvoiceId: string) =>
  apiFetch<void>(`/collectionInvoice/${collectionInvoiceId}/clearErrors`, null, HTTPMethod.PUT);

export const updateStatementTransactions = (transactions: UpdateStatementTransactions[]) =>
  apiFetch<void>('/collectionInvoice/transactions', transactions, HTTPMethod.PUT);

export const cancelReminderFeeInvoiceCreation = (collectionInvoiceId: string) =>
  apiFetch<CollectionInvoice>(`/collectionInvoice/${collectionInvoiceId}/cancelReminderFeeInvoiceCreation`, null, HTTPMethod.PUT);

export const reactivateReminderFeeInvoiceCreation = (collectionInvoiceId: string) =>
  apiFetch<CollectionInvoice>(`/collectionInvoice/${collectionInvoiceId}/reactivateReminderFeeInvoiceCreation`, null, HTTPMethod.PUT);

export const GetCollectionInvoicePaymentTransactions = (collectionInvoiceId: string) =>
  apiFetch<PaymentTransactionListItem[]>(`/collectionInvoice/${collectionInvoiceId}/paymentTransactions`);