import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus, selectAsyncResource, setAsyncResource } from 'store';
import { replaceBy } from 'utility';
import {
  getCollectionInvoices,
  sendStatements,
  getCollectionInvoiceDetails,
  deleteCollectionInvoices,
  getStatementPdfGeneratorStatus,
  getCollectionInvoice,
  getCollectionInvoicesForCompanyId,
} from '.';
import { CollectionInvoice, CollectionInvoiceType } from './interface';

const statementCollectionInvoices = ['statements'];

export const useStatements = () => {
  const {
    data: statements = [],
    status = AsyncStatus.NotInitialized,
  } = useSelector(
    selectAsyncResource<CollectionInvoice[]>([], statementCollectionInvoices)
  );
  const dispatch = useDispatch();

  const loadStatements = useCallback((fromDate: string, toDate: string) => {
    dispatch(setAsyncResource(statementCollectionInvoices, AsyncStatus.Loading, []));
    getCollectionInvoices(CollectionInvoiceType.Statement, fromDate, toDate).then(s => {
      dispatch(setAsyncResource(statementCollectionInvoices, AsyncStatus.Success, s));
    }).catch(() => {
      dispatch(setAsyncResource(statementCollectionInvoices, AsyncStatus.Error, []));
    }); }, [dispatch]);

  const loadStatementsForCompanyId = useCallback((companyId: string) => {
    dispatch(setAsyncResource(statementCollectionInvoices, AsyncStatus.Loading, []));
    getCollectionInvoicesForCompanyId(companyId, CollectionInvoiceType.Statement).then(s => {
      dispatch(setAsyncResource(statementCollectionInvoices, AsyncStatus.Success, s));
    }).catch(() => {
      dispatch(setAsyncResource(statementCollectionInvoices, AsyncStatus.Error, []));
    }); }, [dispatch]);

  const deleteStatements = useCallback((ids: string[]) =>
    deleteCollectionInvoices(ids).then(deletedStatementIds => {
      const filteredStatements = statements?.filter(s => !deletedStatementIds.includes(s.id));
      dispatch(setAsyncResource(statementCollectionInvoices, AsyncStatus.Success, filteredStatements));
      return deletedStatementIds;
    }), [dispatch, statements]);

  const refreshStatementInvoice = useCallback((companyId: string, collectionInvoiceId: string) => {
    if (statements) {
      getCollectionInvoice(companyId, collectionInvoiceId).then(ci => {
        const updatedInvoices = replaceBy('id', ci, statements);
        dispatch(setAsyncResource(statementCollectionInvoices, AsyncStatus.Success, updatedInvoices));
        return ci;
      });
    }
  }, [statements, dispatch]);

  return {
    loadStatements,
    loadStatementsForCompanyId,
    status,
    statements,
    sendStatements,
    getCollectionInvoiceDetails,
    deleteStatements,
    getStatementPdfGeneratorStatus,
    refreshStatementInvoice,
  };
};